import React, { useEffect } from "react"
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import BannerModuleWrapper from "../components/BannerModule/BannerModuleWrapper"
import { graphql } from 'gatsby'
import BasicTextModule from "../components/BasicTextModule/BasicTextModule"
import BasicTextPhotoModule from "../components/BasicTextPhotoModule/BasicTextPhotoModule"


export default function ChiSiamoPage({ data }) {

    

    console.log("data",data)

    const titles = ["ALA - APS è un'associazione di promozione sociale si occupa di", "ALA - APS è un'associazione giovanile di promozione sociale che si occupa di ", "Rifugio Cognolo", "Rifugio Cognolo"]
    const subtitles = ["sviluppo locale, educazione ambientale, formazione, volontariato, politiche giovanili, ", "inclusione e giustizia sociale, cittadinanza attiva, partecipazione ed intercultura.", "È raggiungibile solo a piedi con minimo 2 ore di cammino", "È aperto tutto l'anno solo su prenotazione"]
    const linkTexts = ["approfondisci", "Scopri le attività", "Come raggiungerci", "Prenota"]
    const linkUrls =["#approfondisci","/attivita","/dove","/contatti"]

    return (
        <>
            <Seo title="Chi Siamo" />
            <Layout>
                {<BannerModuleWrapper
                    max={1}
                    titles={titles}
                    subtitles={subtitles}
                    linkTexts={linkTexts}
                    images={data.allWp.edges[0].node.cognoloSettings.backgrounds_chisiamo.bgsChisiamo}
                    punto = ":"
                    linkUrls={linkUrls}
                />}
                <BasicTextPhotoModule
                id="approfondisci"
                 titolo={data.allWp.edges[0].node.cognoloSettings.chiSiamo_testi.titoloSezione} 
                 content={data.allWp.edges[0].node.cognoloSettings.chiSiamo_testi.testi} 
                 image={data.allWp.edges[0].node.cognoloSettings.chiSiamo_testi.immagineSezione}
                 />
                {/*  <PerksModuleDove>
          <Perk title="The Title" content="The content" />
        </PerksModuleDove> */}
                <BasicTextModule

                    content=""
                    className="first-text"
                />
                

            </Layout>
        </>
    )
}



export const imagesWp = graphql`
{
    allWp {
        edges {
          node {
            cognoloSettings {
            chiSiamo_testi {
                testi {
                    paragrafo
                }
                titoloSezione
                immagineSezione {
                    localFile{
                        childrenImageSharp {
                            gatsbyImageData(
                                placeholder: BLURRED
                                formats: AUTO
                                layout: FULL_WIDTH
                            
                            )
                        }
                    }
                }
            }
            backgrounds_chisiamo {
                bgsChisiamo{
                    localFile {
                        childrenImageSharp {
                          gatsbyImageData(
                            aspectRatio: 1.7
                            placeholder: BLURRED
                            formats: AUTO
                            layout: FULL_WIDTH
                          )
                        }
                      }
                    }
              }
            }
          }
        }
      }
}
`