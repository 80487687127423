import * as React from "react"
import Button from "../Button/Button"
import { BasicTextModuleStyles } from "./BasicTextModuleStyles"
/* import { Link } from "gatsby" */

const BasicTextModule = ({ title, content,  linkText, className="" , marginBottomText="30px" }) => {
  return (
    <BasicTextModuleStyles  >
      <div className="container container__tight px-0">
        <div>
          {/* {title && <h2>{title}</h2>} */}
         
          { content && <div dangerouslySetInnerHTML={{ __html: content }} style={{ marginBottom: marginBottomText, color:"var(--bodyColor)" }}></div>}
         {/*  <Button text={linkText} as={Link} to={link} /> */}
        </div>
      </div>
    </BasicTextModuleStyles>
  )
}

export default BasicTextModule
