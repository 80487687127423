import styled from "styled-components"

export const BasicTextPhotoModuleStyles = styled.section`
    margin-top: var(--sectionMargin);
    margin-bottom: var(--sectionMargin);
    background: var(--primary);
  a {
    color:#000;
  }
  
  .container-wrapper {
    display:flex;
    flex-direction: column;
    background: var(--primary);
    

    @media (min-width: 768px) {
    flex-direction: row;
      > div {
          color:var(--bodyColor);
         width: 66.666%;
        max-width: 1000px; 
      }
    }
  }


`
