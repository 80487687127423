import * as React from "react"
import BasicTextModule from "../../components/BasicTextModule/BasicTextModule"
import { GatsbyImage, getImage ,StaticImage} from 'gatsby-plugin-image'
//import Button from "../Button/Button"
import { BasicTextPhotoModuleStyles } from "./BasicTextPhotoModuleStyles"
//import { Link } from "gatsby"

const BasicTextPhotoModule = (data) => {
    const image = getImage(data.image.localFile.childrenImageSharp[0])
    //  const image = getImage(props.images.childrenImageSharp[0])
    /* console.log("titolo",data) */
    return (
        <BasicTextPhotoModuleStyles className="section" id={data.id? data.id : "banner-text-photo"}>
            <div className="container container-wrapper" >
                <div className={" section ps-md-0"}>

                    <h2 dangerouslySetInnerHTML={{ __html: data.titolo }}></h2>

                    {/* <BasicTextModule
                        title="Un progetto di riqualificazione di uno spazio pubblico a cura dell'Associazione ALA - APS."
                        content="Si tratta di una struttura di accoglienza di media montagna situata nel cuore dei monti sabini rimasta chiusa per tanti anni, trasformato in uno spazio laboratorio outdoor dove sperimentare la creatività, la manualità e l'innovazione al servizio dell'ambiente."
                        className="first-text"
                    /> */}

                    {/* {data.content.testi.map((item, index) => (
                        <div key={index} dangerouslySetInnerHTML={{ __html: item.paragrafo }} ></div>
                    ))} */}
                    
                    {data.content.map((item, index) => (
                        <BasicTextModule key={index}  className="last-text" marginBottomText="0px" content={ item.paragrafo } ></BasicTextModule>
                    ))} 
                    

                    {/* <BasicTextModule
                        content="Un luogo dove fare esperienza e vivere la relazione con la natura in maniera diversa, più consapevolmente, con attenzione alle risorse ed il loro utilizzo, nel rispetto  della flora e della fauna locale.
    Per questo rappresenta il setting ideale per attività didattiche con bambini, laboratori con adolescenti ed adulti, attività di team building, trekking di più giorni, week end  per famiglie e piccoli gruppi."
                        className="last-text"
                        marginBottomText="0px"
                    /> */}
                </div>
                <div className="section ps-md-0 pe-md-0" style={{textAlign:"center"}}>

                    {/* <StaticImage
                        src="../../../static/albero.jpg"
                        alt="Perk Image"
                        layout="constrained"
                        width ={350}
                        placeholder="tracedSVG"
                       // transformOptions={{duotone: { highlight: "#ffc400", shadow: "#ffffff", opacity: 50 } }} 
                       
                    /> */}
                    <GatsbyImage
                    
                    
                    image={image}
                    alt={"testo alt"}
                  />
                </div>
            </div>
        </BasicTextPhotoModuleStyles>
    )
}

export default BasicTextPhotoModule
